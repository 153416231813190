import axios from "axios";
import { API_URL } from '@src/configs/environment'

export const API = axios.create({
  baseURL: API_URL,
  withCredentials:true
});

export const forgotPassword = async (credentials) => {
  try {
    const { data } = await API.post("/auth/forgotPassword", credentials);
    return data;
  } catch (e) {
   
    const {response} = e
    throw Error(response.data.message);
  }
};

export const resetPassword = async (credentials) => {
    try {
      const { data } = await API.post("/auth/updatePassword", credentials);
      return data;
    } catch (e) {
        const {response} = e
        throw Error(response.data.message);
    }
  };

export const verifyToken = async (token)=> { 
    try{
       const {data} =  await API.post("/auth/validate",{token})
       return data;
    }catch(e){
        throw Error(e.message)
    }
}

export const updateUser = async (userData)=>{
    try{
        const {data} = await API.post("/auth/invitation/setUserData",userData,{ withCredentials:true})
        return data;
    }catch(e){ 
        throw Error(e.message)
    }
}

export const updateAddress = async (address) => { 
    try{
        const {data} = await API.post("/auth/user/address",address)
        return data;
    }catch(e){ 
        throw Error(e.message)
    }
}

export const verifyPhone = async (phone) => {
    try {
      const { data } = await API.post("/verification/phone", phone);
      return { data: data };
    } catch (e) {
      return { data: e.response.data };
      //return { data: e.response.data, error: true };
    }
  };

export const verifyCode = async (code) => {
    try {
      const { data } = await API.post("/verification/token", code);
      return { data: data };
    } catch (e) {
      return { data: e.response.data };
    }
  };

export const veriffSession = async (obj)=> {
    try{ 
        const { data } = await API.post("/auth/veriff/session", obj);
        return data;
    }catch(e){
        throw Error(e.message)
    }

}

export const getProfile = async (id)=> {
    try{
        const {data} = await API.get(`/auth/profile/${id}`)
        console.log(data.data)
        return data.data
    } catch(e){ 
        const {response} = e
        throw Error(response.data.message);
    }
}

export const getAccess = async ()=> {
    try{
        const {data} = await API.get("/auth/access")
        console.log(data)
        return data
    } catch(e){
        throw Error(e.message);
    }
  }
export const getAdminProfile = async (id)=> {
  try{
      const {data} = await API.get(`/admin/profile/${id}`)
      console.log(data.data)
      return data.data
  } catch(e){ 
      const {response} = e
      throw Error(response.data.message);
  }
}

export const uploadLegalFile = async (payload)=> {
  try{
      console.log(payload.file)
      const formData = new FormData()
      formData.append('file', payload.file)
      const {data} = await API.post(`/admin/legal/proof/${payload.id}`, formData)
      console.log(data.data)
      return { data: data };
  } catch(e){ 
      const {response} = e
      throw Error(response.data.message);
  }

}
