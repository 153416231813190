import { createSlice } from "@reduxjs/toolkit"


export const loginSlice =  createSlice(
    {
        name: 'login',
        initialState: {
            // email:'',
            failedTimes: 0,
            forgotsSent: 0,
            forgotTimer: { hours: 0, minutes: 0, seconds: 0 }
        },
        reducers:{
            // setUsrEmail: (state, action) => {
            //     if (action.type === 'settings/setUsrEmail') {
            //         state.email = action.payload
            //     }
            // },
            setFailedTimes: (state, action) => {
                if (action.type === 'login/setFailedTimes') {
                    state.failedTimes = action.payload
                }
            },
            setForgotsSent: (state, action) => {
                if (action.type === 'login/setForgotsSent') {
                    state.forgotsSent = action.payload
                }
            },
            setForgotTimer: (state, action) => {
                if (action.type === 'login/setForgotTimer') {
                    state.forgotTimer = action.payload
                }
            }
        }
    }
)

export const { setUsrEmail, setFailedTimes, setForgotsSent, setForgotTimer } = loginSlice.actions

export default loginSlice.reducer