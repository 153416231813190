import { Mail, Home } from "react-feather"

const main_route = "/baseline"
export default [
  {
    id: "home",
    title: "Home",
    icon: <Home size={20} />,
    navLink: `${main_route}/home`
  },
  {
    id: "secondPage",
    title: "Second Page",
    icon: <Mail size={20} />,
    navLink: `${main_route}/second-page`
  }
]
