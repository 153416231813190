import React, { useEffect } from 'react'
import { Veriff } from '@veriff/js-sdk'
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk'
import '../../assets/scss/style.scss'
import { useMutation } from '@tanstack/react-query'
import { veriffSession } from '../../http-common'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import APIService from '@src/services/API'
import { Button, Col, Row } from 'reactstrap'
import { JpButton } from '@Intelli/utilities'
import { API_KEY_VERIFF } from '@src/configs/environment'

const VeriffDocument = ({
  data,
  stepper,
  setWentBack,
  currentIndex,
  goBack = true,
  skipped = false
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const API = new APIService()

  const [searchParams, setSearchParams] = useSearchParams()

  const token = searchParams.get('token')

  const { mutate } = useMutation(credentials => API.VeriffSession(credentials,token))

  const { mutate: finishInivte } = useMutation(() => API.FinishInvitation(), {
    onSuccess: () => {
      navigate('/lobby')
    }
  })

  useEffect(() => {
    const veriff = Veriff({
      apiKey: API_KEY_VERIFF,
      parentId: 'veriff-root',
      onSession: function (err, response) {
        // received the response, verification can be started now
        if (response.status === 'success') {
          mutate({
            user_id: data.user.id,
            veriff_id: response.verification.id
          })
          createVeriffFrame({
            url: response.verification.url,
            onEvent: function (msg) {
              switch (msg) {
                case MESSAGES.FINISHED: {
                  !skipped ? finishInivte() : navigate('/lobby')
                }
              }
            }
          })
        }
      }
    })
    veriff.setParams({
      person: {
        givenName: ' ',
        lastName: ' '
      },
      vendorData: !skipped ? 'admin' : 'skipped-user'
    })

    veriff.mount({
      submitBtnText: 'Veriff ✔'
    })
  }, [])
  return (
    <Row className="register-content d-flex flex-column justify-content-center align-items-center">
      <div className="content-header mb-2 text-center">
        <h2 className="fw-bolder mb-75">
          {t('title.Title-IdentityVerification', { ns: 'modules' })}
        </h2>
        <span>
          {t('validation.Text-VerificationInstructions', { ns: 'modules' })}
        </span>
      </div>
      <JpButton
        type="skip"
        color="link"
        options={{ onClick: finishInivte, iconless: true }}
        className="me-75"
      />
      <div
        id="veriff-root"
        className="d-flex justify-content-center text-center"
      />
      {goBack && (
        <div className="justify-content-start">
          <JpButton
            type="back"
            options={{
              onClick: () => {
                setWentBack(true)
                stepper.to(currentIndex)
              },
              iconPosition: 'left'
            }}
          />
        </div>
      )}
    </Row>
  )
}

export default VeriffDocument
