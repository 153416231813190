import React, { useEffect } from 'react'
import { Veriff } from '@veriff/js-sdk'
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk'
import '../../assets/scss/style.scss'
import { useMutation } from '@tanstack/react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import APIService from '@src/services/API'
import { Row } from 'reactstrap'
import { JpButton, SpinnerLoader } from '@Intelli/utilities'
import { API_KEY_VERIFF } from '@src/configs/environment'

const VeriffDocument = ({ data }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const API = new APIService()

  const [searchParams] = useSearchParams()

  const token = searchParams.get('token')

  const { mutate } = useMutation(credentials =>
    API.VeriffSession(credentials, token)
  )

  const { mutate: finishInivte } = useMutation(() => API.FinishInvitation(), {
    onSuccess: () => {
      navigate('/lobby')
    }
  })

  useEffect(() => {
    const veriff = Veriff({
      apiKey: API_KEY_VERIFF,
      parentId: 'veriff-root',
      onSession: function (err, response) {
        if (response.status === 'success') {
          mutate({
            user_id: data.user.id,
            veriff_id: response.verification.id
          })
          createVeriffFrame({
            url: response.verification.url,
            onEvent: function (msg) {
              switch (msg) {
                case MESSAGES.FINISHED: {
                  finishInivte()
                }
              }
            }
          })
        }
      }
    })
    veriff.setParams({
      person: {
        givenName: data?.user?.first_name || ' ',
        lastName: data?.user?.last_name || ' '
      },
      vendorData: 'contractor'
    })

    veriff.mount({
      submitBtnText: 'Veriff ✔'
    })
  }, [])

  return (
    <Row className="justify-content-center align-items-center">
      <div className="content-header mb-2 text-center">
        <h2 className="fw-bolder mb-75">
          {t('title.Title-IdentityVerification', { ns: 'modules' })}
        </h2>
        <span>
          {t('validation.Text-VerificationInstructions', { ns: 'modules' })}
        </span>
      </div>
      {!data.isContractProcess ? (
        // <Button
        //   className='p-0 mb-1'
        //   color="link"
        //   onClick={() => {
        //     finishInivte()
        //   }}
        // >
        //   {t('button.Btn-Skip', { ns: 'modules' })}
        // </Button>
        <JpButton
          type="skip"
          color="link"
          options={{ onClick: finishInivte, iconless: true }}
          className="me-75"
        />
      ) : null}
      <div
        id="veriff-root"
        className="d-flex justify-content-center text-center"
      />
    </Row>
  )
}

export default VeriffDocument
