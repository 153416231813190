// ** React Imports
import { Outlet } from "react-router-dom"

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from "@layouts/VerticalLayout"

// ** Menu Items Array
import navigation from "@src/navigation/vertical"

// ** Third Party Components
import { useTranslation } from 'react-i18next'

const CustomFooter = () => {
  // ** Hooks
  const { t } = useTranslation()
 return (
   <div className="d-flex justify-content-between">
     <small>{t('Footer-CopyText')}</small>
     <small>{t('Footer-MadeBy')}</small>
   </div>
 )
}


const VerticalLayout = (props) => {
  // const [menuData, setMenuData] = useState([])

  // ** For ServerSide navigation
  // useEffect(() => {
  //   axios.get(URL).then(response => setMenuData(response.data))
  // }, [])

  return (
    <Layout menuData={navigation} footer={<CustomFooter/>} {...props}>
      <Outlet />
    </Layout>
  )
}

export default VerticalLayout
