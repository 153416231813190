import React, { Suspense } from 'react'

// ** Router Import
import Router from './router/Router'

// ** React Imports
import { BrowserRouter } from 'react-router-dom'

// ** Redux Imports
import { store, persistor } from './redux/store'
import { Provider } from 'react-redux'

//** React Query imports
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// ** ThemeColors Context
import { ThemeContext } from './utility/context/ThemeColors'

// ** ThemeConfig
import themeConfig from './configs/themeConfig'

// ** Toast
import { Toaster } from 'react-hot-toast'

// ** i18n
import './configs/i18n'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Hot Toast Styles
import '@styles/react/libs/react-hot-toasts/react-hot-toasts.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
import i18next from 'i18next'
import { language$ } from '@Intelli/utilities'
import { PersistGate } from 'redux-persist/integration/react'

const App = () => {
  
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  })

  language$.subscribe(lang => {
    i18next.changeLanguage(lang)
  })

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>  
            <Suspense fallback={<Spinner />}>
              <ThemeContext>
                <Suspense fallback={null}>
                  <Router />
                </Suspense>
                <Toaster
                  position={themeConfig.layout.toastPosition}
                  toastOptions={{ className: 'react-hot-toast' }}
                />
              </ThemeContext>
            </Suspense>
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App
