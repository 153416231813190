import axios from 'axios'
import moment from 'moment-timezone'
import { API_URL } from '@src/configs/environment'
import { globalHandler } from '@Intelli/utilities'
import { t } from 'i18next'

const stringQueryByJson = params =>
  Object.keys(params)
    .filter(key => !['', undefined].includes(params[key]))
    .map(key => key + '=' + params[key])
    .join('&')

export default class APIService {
  constructor(baseURL = API_URL) {
    this.API = new axios.create({
      baseURL,
      withCredentials: true,
      headers: {
        lng: localStorage.getItem('i18nextLng')
          ? localStorage.getItem('i18nextLng')
          : 'en',
        timezone: moment.tz.guess(true)
      }
    })
    this.API.interceptors.response.use(
      request => {
        return request
      },
      error => {
        globalHandler(error.response.data, t)
        return Promise.reject(error)
      }
    )
  }

  PaymentGatewayPaymentLink(token) {
    return this.API.get(
      `v1/payment_gateway/customer/payment/link?token=${token}`
    )
  }

  async login(credentials) {
    try {
      const response = await this.API.post('v1/auth/login', credentials)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async logout() {
    try {
      const response = await this.API.post('v1/auth/logout')
      localStorage.clear()
      window.location.href = '/'
      return response.data
    } catch (e) {
      throw e.response
    }
  }

  async clearCookies() {
    try {
      const response = await this.API.post('v1/auth/logout')
      localStorage.clear()
      return response.data
    } catch (e) {
      throw e.response
    }
  }

  async SendPhoneCode(phone) {
    try {
      return await this.API.post('v1/verification/phone/send', phone)
    } catch (e) {
      throw e.response.data
    }
  }

  async VerifyPhoneCode(code) {
    try {
      await this.API.post('v1/verification/phone/validate', code)
      return true
    } catch (e) {
      throw e
    }
  }

  async forgotPassword(credentials) {
    try {
      const response = await this.API.post(
        'v1/auth/forgotPassword',
        credentials
      )
      return response.data
    } catch (e) {
      throw e.response.data
    }
  }

  async validateToken(token) {
    try {
      const response = await this.API.post('v1/auth/validation/token', token)
      return response.data
    } catch (e) {
      console.log('err', e)
      throw e.response.data
    }
  }

  async resetPassword(pwd) {
    try {
      const response = await this.API.post(
        'v1/auth/forgotPassword/update',
        { password: pwd.password },
        { headers: { Authorization: `Bearer ${pwd.token}` } }
      )
      return response.data
    } catch (e) {
      throw e.response.data
    }
  }

  async userAccess() {
    try {
      const response = await this.API.get('v1/auth/session/lobby')
      return response.data
    } catch (e) {
      throw e.response.data
    }
  }

  async ValidateInvitationToken(token) {
    try {
      const response = await this.API.get('v1/auth/invitation', {
        headers: { Authorization: `Bearer ${token}` }
      })
      return response.data
    } catch (e) {
      console.log(e)
      throw e.response
    }
  }

  async UpdatePersonalInfo(data, token) {
    try {
      const response = await this.API.put('v1/auth/user', data, {
        headers: { Authorization: `Bearer ${token}` }
      })
      return response.data
    } catch (e) {
      throw e.response.data
    }
  }

  async UpdatePassword(data, token) {
    try {
      const response = await this.API.put('v1/auth/invitation/password', data, {
        headers: { Authorization: `Bearer ${token}` }
      })
      // const response = await this.API.put("/auth/invitation/password", data, {headers: {'Authorization': `Bearer ${token}`}})
      return response.data
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  async UpdateEmail(data, token) {
    try {
      const response = await this.API.put('v1/auth/user/email', data, {
        headers: { Authorization: `Bearer ${token}` }
      })
      return response.data
    } catch (e) {
      throw e.response.data
    }
  }

  async UpdatePhone(data, token) {
    try {
      const response = await this.API.put('v1/auth/user/phone', data, {
        headers: { Authorization: `Bearer ${token}` }
      })
      return response.data
    } catch (e) {
      throw e.response.data
    }
  }

  async UpdateAddress(data, token) {
    try {
      const response = await this.API.put('v1/auth/user/address', data, {
        headers: { Authorization: `Bearer ${token}` }
      })
      return response.data
    } catch (e) {
      throw e.response.data
    }
  }

  async FinishInvitation() {
    try {
      const response = await this.API.post('v1/auth/invitation/finish')
      return response.data
    } catch (e) {
      throw e.response.data
    }
  }

  /*   async VeriffSession(obj,token) {
    try {
      const { data } = await this.API.post('v1/auth/veriff/session', obj, {
        headers: { Authorization: `Bearer ${token}` }
      })
      return data
    } catch (e) {
      throw e.message
    }
  } */

  async VeriffSession(obj, token) {
    try {
      const config = token
        ? { headers: { Authorization: `Bearer ${token}` } }
        : {}
      const { data } = await this.API.post(
        'v1/auth/veriff/session',
        obj,
        config
      )
      return data
    } catch (e) {
      throw e.message
    }
  }

  async send2faCode() {
    try {
      const response = await this.API.post('v1/verification/twoFactor/send')
      /* console.log(response) */
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  async validate2faCode(data) {
    try {
      const response = await this.API.post(
        'v1/verification/twoFactor/validate',
        data
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  async set2fa(data) {
    try {
      /*  console.log(data) */
      const response = await this.API.post('v1/auth/session/twoFactor', data)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  async uploadDocument(data) {
    try {
      const formData = new FormData()
      formData.append('file', data.file)
      /* console.log(formData, data.file) */
      const response = await this.API.post(
        'v1/admin/user/legal_representative/document',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async uploadCurriculum(data) {
    try {
      const formData = new FormData()
      formData.append('file', data.file)
      /* console.log(formData, data.file) */
      const response = await this.API.post(
        'v1/auth/user/curriculum',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  async changeAdminWorkspace(data) {
    try {
      const response = await this.API.post(
        'v1/auth/session/workspace/admin',
        data
      )

      return response.data
    } catch (error) {
      throw error
    }
  }

  async changeContractorWorkspace(data) {
    try {
      const response = await this.API.post(
        'v1/auth/session/workspace/contractor',
        data
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getContractSignUrl(token) {
    try {
      const response = await this.API.post(
        'v1/auth/session/veriff/token',
        {},
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      return response.data
    } catch (error) {
      throw error
    }
  }
  async getIndustryType(query = {}) {
    try {
      const response = await this.API.get(
        `v1/backoffice/industry_type/login/search?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async createClient(data) {
    try {
      const response = await this.API.post('v1/backoffice/clients/new', data)
      return response.data
    } catch (e) {
      throw e.response.data
    }
  }
}
