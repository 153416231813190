import { useEffect } from 'react'
import { Col, Form, Input, Label, Row, FormFeedback } from 'reactstrap'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { getDataSet, reduce } from 'iso3166-2-db'
import { useTranslation } from 'react-i18next'
import APIService from '@src/services/API'
import { JpCountryInput, JpButton, JpStateInput } from '@Intelli/utilities'
import i18next from 'i18next'
import { useNavigate } from 'react-router-dom'

const Address = ({
  index,
  stepper,
  data,
  isLegalRepresentative,
  currentIndex,
  location,
  setWentBack = () => {}
}) => {
  const listOfCountries = reduce(getDataSet(), i18next.language)

  const API = new APIService()
  const navigate = useNavigate()

  // useEffect(() => {
  //   if (
  //     data.address !== false &&
  //     data.address !== undefined &&
  //     data.address !== null &&
  //     currentIndex === index &&
  //     stepper
  //   ) {
  //     stepper.next()
  //     setCurrentIndex(currentIndex + 1)
  //   }
  // }, [data, stepper, currentIndex, index, setCurrentIndex])

  const { t } = useTranslation()

  const defaultValues = {
    first_line: '',
    second_line: '',
    user_id: data?.user_id,
    city: '',
    region: {
      value: listOfCountries['US'].regions[0].iso,
      label: listOfCountries['US'].regions[0].name
    },
    country: { value: 'US', label: listOfCountries['US'].name },
    zip_code: ''
  }

  const SignupSchema = yup.object().shape({
    country: yup.object().shape({
      value: yup.string().required('Country is required'),
      label: yup.string().required('Country is required')
    }),
    region: yup.object().required('Region is required'),
    city: yup.string().max(60, 'CITY.max').required('CITY.required'),
    first_line: yup.string().required('LINE1.required').max(60, 'LINE1.max'),
    second_line: yup.string().max(60, 'LINE2.max'),
    zip_code: yup
      .string()
      .transform(value => (isNaN(value) ? undefined : value))
      .min(1, 'ZIP_CODE.min')
      .max(10, 'ZIP_CODE.max')
      .required('ZIP_CODE.required')
  })

  const { mutate: finishInivte } = useMutation(() => API.FinishInvitation(), {
    onSuccess: () => {
      navigate('/lobby')
    }
  })

  // ** Hooks
  const { mutate } = useMutation(
    data =>
      API.UpdatePersonalInfo(data, sessionStorage.getItem('invite_token')),
    {
      onSuccess: () => {
        setWentBack(false)
        if (isLegalRepresentative === false) {
          finishInivte()
        } else {
          stepper.next()
        }
      },
      onError: e => {
        console.log(e)
      }
    }
  )
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(SignupSchema)
  })

  useEffect(() => {
    if (location !== undefined) {
      setValue('country', {
        value: `${location?.country_code.toUpperCase()}`,
        label: listOfCountries[`${location?.country_code.toUpperCase()}`].name
      })
    } else {
      setValue('country', {
        value: 'US',
        label: listOfCountries['US'].name
      })
    }
  }, [location])

  const onSubmit = data => {
    mutate({
      address: {
        ...data,
        country: data.country.value,
        region: data.region.value
      }
    })
  }

  return (
    <>
      <div className="register-content d-flex flex-column justify-content-center">
        <div className="content-header mb-2 text-center">
          {data?.flow !== 'client_invitation' ? (
            <>
              <h2 className="fw-bolder mb-75">
                {t('title.Title-AccountInformation', { ns: 'modules' })}
              </h2>
              <span>{t('address.Text-AddressDetails', { ns: 'modules' })}</span>
            </>
          ) : (
            <h2 className="fw-bolder mb-75">
              {t('title.Title-CompanyInformation', { ns: 'modules' })}
            </h2>
          )}
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="justify-content-center">
            <Col md="3" sm="12" className="mb-1">
              <Label className="form-label" for="firstName">
                {`${t('address.Text-AddressLine1', { ns: 'modules' })}*`}
              </Label>
              <Controller
                control={control}
                id="first_line"
                name="first_line"
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Av 123"
                    invalid={errors.first_line && true}
                    {...field}
                  />
                )}
              />
              {errors.first_line && (
                <FormFeedback>
                  {t(`errors.form.${errors.first_line.message}`, {
                    ns: 'global'
                  })}
                </FormFeedback>
              )}
            </Col>
            <Col md="3" sm="12" className="mb-1">
              <Label className="form-label" for="second_line">
                {t('address.Text-AddressLine2', { ns: 'modules' })}
              </Label>
              <Controller
                control={control}
                id="second_line"
                name="second_line"
                render={({ field }) => (
                  <Input
                    type="text"
                    invalid={errors.second_line && true}
                    placeholder="Av 123"
                    {...field}
                  />
                )}
              />
              {errors.second_line && (
                <FormFeedback>
                  {t(`errors.form.${errors.second_line.message}`, {
                    ns: 'global'
                  })}
                </FormFeedback>
              )}
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md="3" sm="12" className="mb-1">
              <Label className="form-label" for="firstName">
                {`${t('address.Text-City/District', { ns: 'modules' })}*`}
              </Label>
              <Controller
                control={control}
                id="city"
                name="city"
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Jakarta"
                    invalid={errors.city && true}
                    {...field}
                  />
                )}
              />
              {errors.city && (
                <FormFeedback>
                  {t(`errors.form.${errors.city.message}`, { ns: 'global' })}
                </FormFeedback>
              )}
            </Col>
            <Col md="3" sm="12" className="mb-1">
              <Label className="form-label" for="lastName">
                {`${t('address.Text-PostalCode', { ns: 'modules' })}*`}
              </Label>
              <Controller
                control={control}
                id="zip_code"
                name="zip_code"
                render={({ field }) => (
                  <Input
                    type="number"
                    placeholder="12345"
                    invalid={errors.zip_code && true}
                    {...field}
                  />
                )}
              />
              {errors.zip_code && (
                <FormFeedback>
                  {t(`errors.form.${errors.zip_code.message}`, {
                    ns: 'global'
                  })}
                </FormFeedback>
              )}
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="3" sm="12" className="mb-1">
              <Label className="form-label" for="country">
                {`${t('address.Text-Country', { ns: 'modules' })}*`}
              </Label>
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <JpCountryInput field={field} isClearable={false} />
                )}
              />
            </Col>
            <Col md="3" sm="12" className="mb-1">
              <Label className="form-label" for="region">
                {`${t('address.Text-Region/State', { ns: 'modules' })}*`}
              </Label>
              <Controller
                name="region"
                control={control}
                render={({ field }) => (
                  <JpStateInput
                    watch={watch}
                    country={watch('country').value}
                    setValue={setValue}
                    field={field}
                  />
                )}
              />
              <FormFeedback invalid>{errors?.region?.message}</FormFeedback>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="d-flex justify-content-between">
        <JpButton
          type="back"
          options={{
            onClick: () => {
              stepper.to(currentIndex)
              setWentBack(true)
            },
            iconPosition: 'left'
          }}
        />
        <JpButton type="next" options={{ onClick: handleSubmit(onSubmit) }} />
      </div>
    </>
  )
}

export default Address
