import React, { useEffect } from 'react'
import { Veriff } from '@veriff/js-sdk'
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk'
import '../../assets/scss/style.scss'
import { useMutation } from '@tanstack/react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import APIService from '@src/services/API'
import { API_KEY_VERIFF } from '@src/configs/environment'
import { JpButton } from '@Intelli/utilities'

const VeriffDocument = ({ data, stepper }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const API = new APIService()

  const [searchParams, setSearchParams] = useSearchParams()

  const token = searchParams.get('token')

  const { mutate } = useMutation(credentials =>
    API.VeriffSession(credentials, token)
  )

  const { mutate: finishInivte } = useMutation(() => API.FinishInvitation(), {
    onSuccess: () => {
      navigate('/lobby')
    }
  })
  useEffect(() => {
    const veriff = Veriff({
      apiKey: API_KEY_VERIFF,
      parentId: 'veriff-root',
      onSession: function (err, response) {
        if (response.status === 'success') {
          mutate({
            user_id: data.user.id,
            veriff_id: response.verification.id
          })

          createVeriffFrame({
            url: response.verification.url,
            onEvent: function (msg) {
              switch (msg) {
                case MESSAGES.FINISHED: {
                  finishInivte()
                }
              }
            }
          })
        }
      }
    })

    veriff.setParams({
      person: {
        givenName: ' ',
        lastName: ' '
      },
      vendorData: 'operator'
    })

    veriff.mount({
      submitBtnText: 'Veriff ✔'
    })
  }, [])

  return (
    <div className="register-content d-flex flex-column justify-content-center">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <div className="content-header mb-2 text-center">
          <h2 className="fw-bolder mb-75">
            {t('title.Title-IdentityVerification', { ns: 'modules' })}
          </h2>
          <span>
            {t('validation.Text-VerificationInstructions', { ns: 'modules' })}
          </span>
        </div>
        <div id="veriff-root" />
      </div>
      <div className="justify-content-start">
        <JpButton
          type="back"
          options={{ onClick: () => stepper?.previous(), iconPosition: 'left' }}
        />
      </div>
    </div>
  )
}
export default VeriffDocument
