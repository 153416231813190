// ** React Imports
import { useState } from 'react'

// ** Reactstrap Imports
import { Button, ListGroup, ListGroupItem, Row, Col } from 'reactstrap'

// ** Third Party Imports
import toast from 'react-hot-toast'
import { useDropzone } from 'react-dropzone'
import { X, FileText } from 'react-feather'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowUp } from '@fortawesome/pro-solid-svg-icons'
import { useMutation } from '@tanstack/react-query'
import APIService from '@src/services/API'
import { useTranslation } from 'react-i18next'
import { JpButton, SweetModal } from '@Intelli/utilities'

const Document = ({ stepper, data, currentIndex, finishInivte }) => {
  const { t } = useTranslation()

  const API = new APIService()

  // ** State
  const [files, setFiles] = useState([])

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'application/pdf': ['.pdf']
    },
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length) {
        SweetModal(
          'warning',
          t('titles.atention'),
          t('validation.Text-FileUpload', { ns: 'modules' }),
          t('button.Btn-Ok', { ns: 'modules' })
        )
      } else {
        setFiles([...files, ...acceptedFiles.map(file => Object.assign(file))])
      }
    },
    maxSize: 52428800
  })

  const handleRemoveFile = file => {
    const uploadedFiles = files
    const filtered = uploadedFiles.filter(i => i.name !== file.name)
    setFiles([...filtered])
  }

  const renderFileSize = size => {
    if (Math.round(size / 100) / 10 > 1000) {
      return `${(Math.round(size / 100) / 10000).toFixed(1)} MB`
    } else {
      return `${(Math.round(size / 100) / 10).toFixed(1)} KB`
    }
  }

  const fileList = files.map((file, index) => (
    <ListGroupItem
      key={`${file.name}-${index}`}
      className="d-flex align-items-center justify-content-between"
    >
      <div className="file-details d-flex align-items-center text-truncate">
        <div className="file-preview me-1 ">
          <FileText size="28" />
        </div>
        <div>
          <p className="file-name mb-0">{file.name}</p>
          <p className="file-size mb-0">{renderFileSize(file.size)}</p>
        </div>
      </div>
      <Button
        color="danger"
        outline
        size="sm"
        className="btn-icon ms-75"
        onClick={() => handleRemoveFile(file)}
      >
        <X size={14} />
      </Button>
    </ListGroupItem>
  ))

  const uploadMutation = useMutation(data => API.uploadCurriculum(data), {
    onSuccess: () => {
      stepper.next()
    }
  })

  const handleUpload = () => {
    uploadMutation.mutate({ file: files[0] })
    data.hasBeenVerified ? finishInivte() : stepper.next()
    // stepper.next()
  }

  return (
    <>
      <div className="register-content d-flex flex-column justify-content-center ">
        <div className="content-header text-center mb-0">
          <span>{t('title.Title-Welcome', { ns: 'modules' })}</span>
          <h3 className="fw-bolder mb-75">
            {t('title.Title-UploadLegalDocument', { ns: 'modules' })}
          </h3>
        </div>
        {!files.length > 0 && (
          <div
            {...getRootProps({
              className: 'dropzone align-items-center'
            })}
          >
            <input id="template" {...getInputProps()} />
            <div className="d-flex align-items-center justify-content-center flex-column px-5 py-2 border rounded ">
              <FontAwesomeIcon icon={faCloudArrowUp} size="4x" />
              <p className="text-center mt-1">
                {t('title.Title-UploadCV', { ns: 'modules' })}
              </p>
              <p className="text-secondary">
                {t('legal.TextLegalDocumentDrop', { ns: 'modules' })}
                <a href="/" onClick={e => e.preventDefault()}>
                  {t('legal.TextLegalDocumentBrowse', { ns: 'modules' })}
                </a>{' '}
                {t('legal.TextLegalDocumentMachine', { ns: 'modules' })}
              </p>
            </div>
          </div>
        )}
        {files.length ? (
          <Row className="justify-content-center ">
            <Col md="6" className="">
              <ListGroup className="mb-1 text-truncate">{fileList}</ListGroup>
            </Col>
          </Row>
        ) : null}
      </div>
      <div className={`d-flex justify-content-between mt-2`}>
        <JpButton
          type="back"
          options={{
            onClick: () => {
              stepper.to(currentIndex)
            },
            iconPosition: 'left'
          }}
        />
        <div>
          <JpButton
            type="skip"
            options={{
              onClick: () => {
                data.hasBeenVerified ? finishInivte() : stepper.next()
              },
              iconless: true
            }}
            className="me-75"
          />
          {files.length ? (
            <JpButton
              type="next"
              loading={uploadMutation?.isLoading}
              options={{ onClick: handleUpload }}
            />
          ) : null}
        </div>
      </div>
    </>
  )
}

export default Document
