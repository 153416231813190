// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import email from "./email"
import login from "./login"
import { combineReducers } from "redux"

const rootReducer = combineReducers({ navbar, layout, email, login })

export default rootReducer
