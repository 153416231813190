import { useEffect, useState } from 'react'
import { ChevronRight } from 'react-feather'
import {
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Label,
  Row,
  FormFeedback,
  UncontrolledTooltip
} from 'reactstrap'
import InputPasswordToggle from '../../@core/components/input-password-toggle'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import APIService from '@src/services/API'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion, faEnvelope } from '@fortawesome/pro-solid-svg-icons'
import { JpButton } from '@Intelli/utilities'

const NewAcc = ({
  index,
  currentIndex,
  setCurrentIndex,
  stepper,
  data,
  setRegisterData,
  setLegalRepresentative,
  legalRepresentative = false
}) => {


  const API = new APIService()
  const { t, i18n } = useTranslation()

  const defaultValues = {
    first_name: '',
    last_name: '',
    password: '',
    confirm_password: '',
    terms: false
  }

  const [email, setEmail] = useState(data?.email)

  const SignupSchema = yup.object().shape({
    first_name: yup.string().required('Text-FirstNameRequired'),
    last_name: yup.string().required('Text-LastNameRequired'),
    password: yup
      .string()
      .required('Text-PasswordRequired')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&.-])[A-Za-z\d@$!%*#?&.-]{8,}$/,
        'Text-PasswordFormat'
      ),
    confirm_password: yup
      .string()
      .required('Text-ConfirmPasswordRequired')
      .oneOf([yup.ref(`password`), null], 'Text-PasswordsMustMatch'),
    terms: yup.boolean().oneOf([true], 'Text-AcceptTermsConditions')
  })

  // ** Hooks

  const {
    control,
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(SignupSchema)
  })

  const { mutate: mutatePwd } = useMutation(
    pwd => API.UpdatePassword(pwd, sessionStorage.getItem('invite_token')),
    {
      onSuccess: data => {
        // setCurrentIndex(currentIndex + 1)
        if (legalRepresentative) {
          stepper.next()
        } else {
          stepper.next()
          stepper.next()
        }
      },
      onError: error => {
        console.log(error)
      }
    }
  )

  const { mutate: mutatePersonalInfo } = useMutation(
    data =>
      API.UpdatePersonalInfo(data, sessionStorage.getItem('invite_token')),
    {
      onSuccess: response => {
        mutatePwd({ password: getValues('password') })
      }
    }
  )

  //** Handles
  const onSubmit = data => {
    delete data.terms
    setRegisterData(data)
    mutatePersonalInfo({
      user: {
        first_name: data.first_name,
        last_name: data.last_name
      }
    })
  }

  return (
    <>
      <div className="register-content d-flex flex-column justify-content-center">
        <div className="content-header mb-2 text-center">
          <small>
            {t('title.Subtitle-CreateNewAccount', { ns: 'modules' })}
          </small>
          <h2 className="mb-25">
            {t('title.Title-CreateNewAccount', { ns: 'modules' })}
          </h2>
          <span>
            {t('password.Text-UsernamePasswordDetails', { ns: 'modules' })}
          </span>
        </div>
        <div className="text-center mb-1">
          <div className="d-flex text-center justify-content-center align-items-center">
            <FontAwesomeIcon icon={faEnvelope} className="me-25" />
            <Label className="mb-0">
              {t('account.Text-Email', { ns: 'modules' })}
            </Label>
          </div>
          <small>{email}</small>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="justify-content-center">
            <Col md="3" sm="12" className="mb-1">
              <Label for="first_name">
                {t('account.Text-FirstName', { ns: 'modules' })}
              </Label>
              <Controller
                control={control}
                id="first_name"
                name="first_name"
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="John"
                    invalid={errors.first_name && true}
                    {...field}
                  />
                )}
              />
              <FormFeedback invalid>
                {t(`account.${errors?.first_name?.message}`, {
                  ns: 'modules'
                })}
              </FormFeedback>
            </Col>
            <Col md="3" sm="12" className="mb-1">
              <Label for="last_name">
                {t('account.Text-LastName', { ns: 'modules' })}
              </Label>
              <Controller
                control={control}
                id="last_name"
                name="last_name"
                render={({ field }) => (
                  <Input
                    autocomplete="new-password"
                    type="text"
                    placeholder="Doe"
                    invalid={errors.last_name && true}
                    {...field}
                  />
                )}
              />
              <FormFeedback invalid>
                {t(`account.${errors?.last_name?.message}`, {
                  ns: 'modules'
                })}
              </FormFeedback>
            </Col>
          </Row>
          <Row className="justify-content-center mt-2">
            <h6 className="mb-75 text-center">
              {t('password.Text-CreatePassword', { ns: 'modules' })}
            </h6>
            <Col md="3" sm="12" className="mb-1">
              <div className="form-password-toggle mb-1">
                <Controller
                  id="password"
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <InputPasswordToggle
                      label={t('password.Text-NewPassword', {
                        ns: 'modules'
                      })}
                      autocomplete="new-password"
                      htmlFor="password"
                      className="input-group-merge"
                      invalid={errors.password && true}
                      {...field}
                    />
                  )}
                />
                {/* <FormFeedback invalid>
                  {t(`password.${errors?.password?.message}`, {
                    ns: 'modules'
                  })}
                </FormFeedback> */}
                {errors.password && (
                  <FormFeedback>
                    {/* {t(`password.${errors.password.message}`, {
                      ns: 'modules'
                    })} */}
                    <FontAwesomeIcon
                      icon={faCircleQuestion}
                      className="text-danger"
                      id="password-icon"
                    />
                    <UncontrolledTooltip placement="top" target="password-icon">
                      {t(`password.${errors.password.message}`, {
                        ns: 'modules'
                      })}
                    </UncontrolledTooltip>
                  </FormFeedback>
                )}
              </div>
            </Col>
            <Col md="3" sm="12" className="mb-1">
              <div className="form-password-toggle mb-1">
                <Controller
                  control={control}
                  id="confirm_password"
                  name="confirm_password"
                  render={({ field }) => (
                    <InputPasswordToggle
                      label={t('password.Text-ConfirmPassword', {
                        ns: 'modules'
                      })}
                      htmlFor="password"
                      className="input-group-merge"
                      invalid={errors.confirm_password && true}
                      {...field}
                    />
                  )}
                />
                <FormFeedback invalid>
                  {t(`password.${errors?.confirm_password?.message}`, {
                    ns: 'modules'
                  })}
                </FormFeedback>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8" className="mb-1">
              <FormGroup check className="d-flex justify-content-center">
                <input
                  type="checkbox"
                  placeholder="terms"
                  {...register('terms')}
                  className={`form-check-input ${
                    errors.terms && true ? 'is-invalid' : ''
                  }`}
                />
                <small check className="ms-50">
                  {t('password.Text-TermsConditionsA', { ns: 'modules' })}
                  <a
                    href={'https://joobpay.com/privacy-policy/'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('password.Text-TermsConditionsB', { ns: 'modules' })}
                  </a>
                </small>
              </FormGroup>
              <div className="d-flex justify-content-center">
                {errors?.terms && (
                  <small className="text-danger">
                    {t(`password.${errors?.terms?.message}`, {
                      ns: 'modules'
                    })}
                  </small>
                )}
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="d-flex justify-content-end mt-2">
        <JpButton type="next" options={{ onClick: handleSubmit(onSubmit) }} />
      </div>
    </>
  )
}

export default NewAcc
