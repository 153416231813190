// ** React Imports
import { Fragment, useEffect, useState } from 'react'
// ** Third Party Components
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChevronRight } from 'react-feather'

import { useTranslation } from 'react-i18next'

// ** Reactstrap Imports
import {
  Form,
  Label,
  Row,
  Col,
  Button,
  FormFeedback,
  InputGroup,
  InputGroupText,
  Input
} from 'reactstrap'

// ** Custom Components

import ReactCountryFlag from 'react-country-flag'

import {
  parsePhoneNumberFromString,
  getCountries,
  getPhoneCode,
  isPossibleNumber
} from 'libphonenumber-js'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useMutation } from '@tanstack/react-query'
import APIService from '@src/services/API'
import { TimerButton, JpCountryCodesInput, JpButton } from '@Intelli/utilities'

const MySwal = withReactContent(Swal)

const PhoneVerification = ({ data, stepper, setCurrentIndex, location }) => {
  const API = new APIService()
  const { t } = useTranslation()

  // useEffect(() => {
  //   if (data?.phone?.verified === true) {
  //     stepper?.next()
  //     setCurrentIndex(2)
  //   }
  // }, [data, stepper])

  const SignupSchema = yup.object().shape({
    phoneInput: yup
      .string()
      .required('Text-EnterYourPhone')
      .test(
        'phone-isvalid',
        t('Text-PhoneValid', { ns: 'modules' }),
        value => {
          return isPossibleNumber(
            `+${getPhoneCode(getValues('country_code').value)}${value}`,
            getValues('country_code')
          )
        }
      ),
    vcode_1: yup.string().required(),
    vcode_2: yup.string().required(),
    vcode_3: yup.string().required(),
    vcode_4: yup.string().required(),
    vcode_5: yup.string().required(),
    vcode_6: yup.string().required()
  })

  // ** Hooks

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(SignupSchema)
  })

  useEffect(() => {
    if (location !== undefined) {
      setValue('country_code', {
        value: location?.country_code.toUpperCase(),
        label: `${location?.country} (+${getPhoneCode(
          `${location?.country_code.toUpperCase()}`
        )})`
      })
    } else {
      setValue('country_code', {
        value: 'US',
        label: `United States (+${getPhoneCode('US')})`
      })
    }
  }, [location])

  const [tokenId, setTokenId] = useState('')
  const [codeSent, setCodeSent] = useState(false)
  const [phoneIsValid, setPhoneIsValid] = useState(true)
  const [expires, setExpires] = useState({ hours: 0, minutes: 0, seconds: 30 })
  const [isDisabled, setIsDisabled] = useState(false)

  const handleWarning = (warningTitle, warningText) => {
    return MySwal.fire({
      title: warningTitle,
      text: warningText,
      icon: 'warning',
      confirmButtonText: t('button.Btn-ConfirmButtonText', { ns: 'modules' }),
      customClass: {
        confirmButton: 'btn btn-warning'
      },
      buttonsStyling: false
    })
  }

  const phoneMutation = useMutation(
    phone => API.SendPhoneCode(phone, sessionStorage.getItem('invite_token')),
    {
      onSuccess: response => {
        /* console.log(response.data) */
        setTokenId(response.data.token_id)
      },
      onError: error => {
        error.details &&
          handleWarning(
            t('validation.Text-SomethingIsWrong', { ns: 'modules' }),
            error.message
          )
        error?.code === 'VFSV008' && setIsDisabled(false)

        for (const i of error.details) {
          if (i.type === 'validation') {
            const el = document.getElementById(i.property)
            el.classList.add('is-invalid')
          }
        }
      }
    }
  )

  const codeMutation = useMutation(
    code => API.VerifyPhoneCode(code, sessionStorage.getItem('invite_token')),
    {
      onSuccess: response => {
        stepper.next()
        setCurrentIndex(2)
      }
    }
  )

  const onSubmit = data => {
    /* console.log(data)
    console.log(errors) */
    const codePhone =
      getValues('vcode_1') +
      getValues('vcode_2') +
      getValues('vcode_3') +
      getValues('vcode_4') +
      getValues('vcode_5') +
      getValues('vcode_6')
    if (tokenId !== '') {
      codeMutation.mutate({ token_id: tokenId, token: codePhone })
    } else {
      handleWarning(
        t('validation.Text-SomethingIsWrong', { ns: 'modules' }),
        t('validation.Text-PressGetCodeButton', { ns: 'modules' })
      )
    }
  }
  const [country, setCountry] = useState()
  const handleCountryChange = (e, countryCode) => {
    e.preventDefault()
    setCountry(countryCode)
  }

  const options = getCountries().map((country, index) => (
    <option key={index} value={country}>{`${country} +${getPhoneCode(
      country
    )}`}</option>
  ))

  const resetInputsCode = () =>{
    setValue('vcode_1', '')
    setValue('vcode_2', '')
    setValue('vcode_3', '')
    setValue('vcode_4', '')
    setValue('vcode_5', '')
    setValue('vcode_6', '')
  }

  const sendCode = () => {
    resetInputsCode()
    const phone = parsePhoneNumberFromString(
      getValues('phoneInput'),
      getValues('country_code').value.toUpperCase()
    )

    /* console.log('phone', phone) */

    if (phone !== undefined && isPossibleNumber(phone.number)) {
      setPhoneIsValid(true)
      phoneMutation.mutate({ phone: phone.number })
      setIsDisabled(true)
      setTimeout(() => {
        setIsDisabled(false)
      }, expires.seconds * 1000 + expires.minutes * 60 * 1000 + expires.hours * 60 * 60 * 1000)
    } else {
      setPhoneIsValid(false)
    }
  }

  const handleOtpPaste = e => {
    const otp_inputs = document.querySelectorAll('.auth-input-wrapper input')
    const paste = (e.clipboardData || window.clipboardData).getData('text')
    const value = paste.split('')
    if (value.length === 6) {
      otp_inputs.forEach((input, index) => {
        input.value = value[index]
        setValue(`vcode_${index + 1}`, value[index])
      })
    }
  }

  const handleOtp = e => {
    const input = e.target
    const value = input.value
    const field_index = input.tabIndex
    input.value = value ? value[0] : ''
    if (value.length > 0 && field_index < 5) {
      input.nextElementSibling.focus()
    }
    if (e.key === 'Backspace' && field_index > 0) {
      input.previousElementSibling.focus()
    }
    setValue(`vcode_${field_index + 1}`, value)
  }

  const otpError = Object.keys(errors).some(
    key => key.includes('vcode') && errors[key].message
  )

  return (
    <>
      <div className="register-content d-flex flex-column justify-content-center">
        <div className="content-header mb-2 text-center">
          <span>{t('title.Title-Welcome', { ns: 'modules' })}</span>
          <h2 className="fw-bolder mb-75">
            {t('title.Title-CreateNewAccount', { ns: 'modules' })}
          </h2>
          <h4 className=" mt-2">
            {t('validation.Text-VerifyPhone', { ns: 'modules' })}
          </h4>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="justify-content-center">
            <Col lg="3">
              <Label for="country_code">
                {t('address.Text-Country', { ns: 'modules' })}
              </Label>
              <Controller
                name="country_code"
                control={control}
                render={({ field }) => (
                  <JpCountryCodesInput field={field} isClearable={false} />
                )}
              />
              <FormFeedback invalid>
                {errors?.country_code?.message}
              </FormFeedback>
            </Col>
            <Col lg="3">
              <Label for="phone">
                {t('account.Text-Phone', { ns: 'modules' })}
              </Label>
              <Controller
                name="phoneInput"
                control={control}
                rules={{
                  required: t('validation.Text-EnterYourPhone', {
                    ns: 'modules'
                  }),
                  pattern: {
                    value: /^[0-9]*$/,
                    message: t('validation.Text-EnterYourPhone', {
                      ns: 'modules'
                    })
                  }
                }}
                render={({ field }) => (
                  <Input
                    id="phoneInput"
                    type="tel"
                    maxLength={15}
                    invalid={(errors.phoneInput && true) || !phoneIsValid}
                    placeholder="111 222 3333"
                    {...field}
                  />
                )}
              />
              <FormFeedback invalid>
                {t(`validation.${errors?.phoneInput?.message || 'Text-PhoneValid'}`, {
                  ns: 'modules'
                })}
              </FormFeedback>
              {/* <FormFeedback valid={false}>
              {'Please enter a valid phone'}
            </FormFeedback> */}
            </Col>
          </Row>
          <Row className="justify-content-center text-center mt-3 ">
            <h6>{t('validation.Text-EnterYourPhone', { ns: 'login' })}</h6>
            <p>
              {t(
                'validation.Text-PleasePressSendCodeToReceiveSMSWithSecurityCode',
                { ns: 'modules' }
              )}
            </p>
            <small>{t('validation.Text-TypeCode', { ns: 'modules' })}</small>
            <div className="auth-input-wrapper d-flex align-items-center justify-content-center mt-1">
              <input
                {...register('vcode_1')}
                tabIndex={0}
                onKeyUp={handleOtp}
                onPaste={handleOtpPaste}
                autocomplete="off"
                type="tel"
                maxLength="1"
                className={`form-control auth-input height-50 text-center numeral-mask mx-25 mb-1 ${
                  otpError ? 'border-danger' : ''
                }`}
              />
              <input
                {...register('vcode_2', { required: true })}
                tabIndex={1}
                onKeyUp={handleOtp}
                onPaste={handleOtpPaste}
                type="tel"
                autocomplete="off"
                maxLength="1"
                className={`form-control auth-input height-50 text-center numeral-mask mx-25 mb-1 ${
                  otpError ? 'border-danger' : ''
                }`}
              />
              <input
                {...register('vcode_3', { required: true })}
                tabIndex={2}
                onKeyUp={handleOtp}
                onPaste={handleOtpPaste}
                type="tel"
                autocomplete="off"
                maxLength="1"
                className={`form-control auth-input height-50 text-center numeral-mask mx-25 mb-1 ${
                  otpError ? 'border-danger' : ''
                }`}
              />
              <input
                {...register('vcode_4', { required: true })}
                tabIndex={3}
                onKeyUp={handleOtp}
                onPaste={handleOtpPaste}
                type="tel"
                autocomplete="off"
                maxLength="1"
                className={`form-control auth-input height-50 text-center numeral-mask mx-25 mb-1 ${
                  otpError ? 'border-danger' : ''
                }`}
              />
              <input
                {...register('vcode_5', { required: true })}
                tabIndex={4}
                onKeyUp={handleOtp}
                onPaste={handleOtpPaste}
                type="tel"
                autocomplete="off"
                maxLength="1"
                className={`form-control auth-input height-50 text-center numeral-mask mx-25 mb-1 ${
                  otpError ? 'border-danger' : ''
                }`}
              />
              <input
                {...register('vcode_6', { required: true })}
                tabIndex={5}
                onKeyUp={handleOtp}
                onPaste={handleOtpPaste}
                type="tel"
                autocomplete="off"
                maxLength="1"
                className={`form-control auth-input height-50 text-center numeral-mask mx-25 mb-1 ${
                  otpError ? 'border-danger' : ''
                }`}
              />
            </div>
            {codeSent && (
              <small className="text-success">
                {t('validation.Text-MessageSentSuccessfully', {
                  ns: 'modules'
                })}
              </small>
            )}
            {otpError && (
              <small className="text-danger">
                {t(`validation.Text-6Digits`)}
              </small>
            )}
            <TimerButton
              isDisabled={isDisabled}
              onClick={sendCode}
              expires={expires}
              buttonText={t('validation.Text-GetCode', { ns: 'modules' })}
            />
          </Row>
        </Form>
      </div>
      <div className="d-flex justify-content-end">
        <JpButton
          type="next"
          options={{ onClick: handleSubmit(onSubmit) }}
          className="me-3"
        />
      </div>
    </>
  )
}

export default PhoneVerification
