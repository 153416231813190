import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import APIService from '@src/services/API'
import { useQuery } from '@tanstack/react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Row } from 'reactstrap'

const SignRedirect = () => {
  const [params, setParams] = useSearchParams()
  const navigate = useNavigate()
  const API = new APIService()

  useQuery(['token'], () => API.getContractSignUrl(params.get('token')), {
    enabled: !!params.get('token'),
    onSuccess: data => {
      const token = params.get('token')
      if (data.flow === 'sign_contractor')
        navigate(`/wallet/documents/signcontract?token=${token}`)
      else navigate(`/admin/contractors/sign/email?token=${token}`)
    }
  })

  return (
    <div className="auth-wrapper">
      <Row className="justify-content-center align-items-center w-100 text-primary">
        <FontAwesomeIcon
          icon="fak fa-joobpay"
          flip
          size="4x"
          color="primary"
          className="text-primary"
        />
      </Row>
    </div>
  )
}

export default SignRedirect
