// ** React Imports
import { useNavigate } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'
// ** Reactstrap Imports
import { Row, Col, CardText } from 'reactstrap'

// ** Styles
import '@styles/react/pages/page-authentication.scss'
import source from '@src/assets/images/pages/create-account.svg'
import source2 from '@src/assets/images/pages/lateral.jpg'
import logo from '@src/assets/images/logo/logo_white_horizontal.svg'
import { Home, UserPlus, Phone, UserCheck, Briefcase } from 'react-feather'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowUp } from '@fortawesome/pro-solid-svg-icons'

import { useEffect, useRef, useState } from 'react'

import Wizard from '@src/components/stepper'
import NewAcc from './AdminRegister/NewAcc'
import Address from './RegisterWithInvite/Address'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import Veriff from './LegalRepresent/Veriff'
import PhoneVerification from './AdminRegister/PhoneVerification'
import { useMutation } from '@tanstack/react-query'
import Document from './LegalRepresent/Document'
import APIService from '@src/services/API'
import { SpinnerLoader, IntlDropdown } from '@Intelli/utilities'
import LegalConfirmation from './AdminRegister/LegalConfirmation'

const stepSkipMap = {
  hasPassword: 1,
  isLegalRepresentative: 2,
  phone: 3,
  address: 4,
  hasDocumentLegalRepresentative: 5,
  hasBeenVerified: 6
}

const RegisterAdmin = ({ data, isLegalRepresentative, location, mutate }) => {
  // ** Ref
  const refLegal = useRef(null)

  // ** State
  const [stepper, setStepper] = useState()
  const [registerData, setRegisterData] = useState({})
  const API = new APIService()

  // ** Hooks
  const navigate = useNavigate()
  const [legalRepresentative, setLegalRepresentative] = useState(
    isLegalRepresentative
  )
  const [currentIndex, setCurrentIndex] = useState(0)
  const [hasConfirmed, setHasConfirmed] = useState(false)
  const [wentBack, setWentBack] = useState(false)

  const { mutate: finishInivte } = useMutation(() => API.FinishInvitation(), {
    onSuccess: () => {
      navigate('/lobby')
    }
  })

  useEffect(() => {
    if (stepper && data) {
      let stepToSkip = 0

      for (const key of Object.keys(data)
        .filter(key => !!stepSkipMap[key])
        .sort((a, b) => stepSkipMap[a] - stepSkipMap[b])) {
        if (
          !!data[key] &&
          stepSkipMap[key] > stepToSkip &&
          key !== 'isLegalRepresentative' &&
          (hasConfirmed || key === 'hasPassword' || !isLegalRepresentative)
        ) {
          if (stepSkipMap[key] === 3 && !data[key].verified) {
            stepToSkip = 2
            break
          }
          if (
            (stepSkipMap[key] === 6 &&
              data['hasDocumentLegalRepresentative'] === true) ||
            (legalRepresentative === false && stepSkipMap[key] === 4)
          ) {
            finishInivte()
          }

          if (
            key === 'hasPassword' &&
            data['hasDocumentLegalRepresentative'] === false
          ) {
            stepToSkip = stepSkipMap[key]
          } else {
            stepToSkip = stepSkipMap[key]
          }
        } else if (!!!data[key] && key !== 'isLegalRepresentative') {
          break
        }
        // else if (data[key] === false && key === 'isLegalRepresentative') {
        //   stepToSkip = stepSkipMap[key]
        //   break
        // }
      }

      if (
        stepToSkip > 0 &&
        currentIndex < stepToSkip &&
        currentIndex !== 1 &&
        !wentBack
      ) {
        stepper.to(stepToSkip + 1)
        /* mutate() */
      }
    }
  }, [stepper, data, currentIndex, hasConfirmed])

  if (!data || isLegalRepresentative === null) return <SpinnerLoader />

  const steps = [
    {
      id: 'new-account',
      // title: 'Account',
      title: '',
      subtitle: '',
      icon: <UserPlus size={18} />,
      content: (
        <NewAcc
          index={0}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          stepper={stepper}
          data={data}
          setRegisterData={setRegisterData}
          setLegalRepresentative={setLegalRepresentative}
          legalRepresentative={isLegalRepresentative}
        />
      )
    },
    {
      id: 'legal-confirm',
      // title: 'Legal',
      title: '',
      icon: <Briefcase size={18} />,
      content: (
        <LegalConfirmation
          index={1}
          data={data}
          stepper={stepper}
          registerData={registerData}
          setLegalRepresentative={setLegalRepresentative}
          setHasConfirmed={setHasConfirmed}
        />
      )
    },
    {
      id: 'verify-phone',
      // title: 'Phone',
      title: '',
      subtitle: '',
      icon: <Phone size={18} />,
      content: (
        <PhoneVerification
          index={2}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          stepper={stepper}
          legalRepresentative={legalRepresentative}
          data={data}
          location={location}
        />
      )
    },
    {
      id: 'address',
      // title: 'Address',
      title: '',
      subtitle: '',
      icon: <Home size={18} />,
      content: (
        <Address
          index={3}
          stepper={stepper}
          isLegalRepresentative={legalRepresentative}
          data={data}
          location={location}
          currentIndex={currentIndex}
          setWentBack={setWentBack}
        />
      )
    },
    {
      id: 'legal-document',
      // title: 'Document',
      title: '',
      subtitle: '',
      icon: <FontAwesomeIcon icon={faFileArrowUp} size="lg" />,
      content: (
        <Document
          stepper={stepper}
          data={data}
          currentIndex={currentIndex}
          setWentBack={setWentBack}
          finishInvite={finishInivte}
          isLegalRepresentative={legalRepresentative}
        />
      )
    },
    {
      id: 'verify-document',
      // title: 'Identity',
      title: '',
      subtitle: '',
      icon: <UserCheck size={18} />,
      content: (
        <Veriff
          data={data}
          registerData={registerData}
          setWentBack={setWentBack}
          currentIndex={currentIndex}
          stepper={stepper}
        />
      )
    }
  ]

  // const steps_regular = [
  //   {
  //     id: 'new-account',
  //     // title: 'Account',
  //     title: '',
  //     subtitle: '',
  //     icon: <UserPlus size={18} />,
  //     content: (
  //       <NewAcc
  //         index={0}
  //         stepper={stepper}
  //         data={data}
  //         setRegisterData={setRegisterData}
  //         currentIndex={currentIndex}
  //         setCurrentIndex={setCurrentIndex}
  //         setLegalRepresentative={setLegalRepresentative}
  //         legalRepresentative={legalRepresentative}
  //       />
  //     )
  //   },
  //   {
  //     id: 'legal-confirm',
  //     // title: 'Legal',
  //     title: '',
  //     icon: <Briefcase size={18} />,
  //     content: (
  //       <LegalConfirmation
  //         index={1}
  //         currentIndex={currentIndex}
  //         setCurrentIndex={setCurrentIndex}
  //         data={data}
  //         stepper={stepper}
  //         registerData={registerData}
  //         setLegalRepresentative={setLegalRepresentative}
  //         setHasConfirmed={setHasConfirmed}
  //       />
  //     )
  //   },
  //   {
  //     id: 'verify-phone-regular',
  //     // title: 'Phone',
  //     title: '',
  //     subtitle: '',
  //     icon: <Phone size={18} />,
  //     content: (
  //       <PhoneVerification
  //         index={2}
  //         stepper={stepper}
  //         legalRepresentative={legalRepresentative}
  //         currentIndex={currentIndex}
  //         setCurrentIndex={setCurrentIndex}
  //         data={data}
  //       />
  //     )
  //   },
  //   {
  //     id: 'address',
  //     // title: 'Address',
  //     title: '',
  //     subtitle: '',
  //     icon: <Home size={18} />,
  //     content: (
  //       <Address
  //         index={3}
  //         currentIndex={currentIndex}
  //         setCurrentIndex={setCurrentIndex}
  //         stepper={stepper}
  //         isLegalRepresentative={
  //           legalRepresentative === null
  //             ? isLegalRepresentative
  //             : legalRepresentative
  //         }
  //         data={data}
  //       />
  //     )
  //   }
  // ]

  // if (isLegalRepresentative === false || legalRepresentative === false) {
  //   return (
  //     <div className="auth-wrapper auth-cover  d-flex justify-content-center ">
  //       <div>
  //         <CardText className="text-end nav-buttons" style={{ minWidth: 100 }}>
  //           <ul className="nav navbar-nav align-items-center ms-auto">
  //             <IntlDropdown />
  //           </ul>
  //         </CardText>
  //       </div>
  //       <Row className="auth-inner m-0">
  //         <img
  //           style={{ maxWidth: '250px' }}
  //           src={logo}
  //           alt="logo"
  //           className="brand-logo"
  //         />
  //         <Col lg="3" className="d-none d-lg-flex align-items-center p-0">
  //           <div className="w-100 d-lg-flex align-items-center justify-content-center register-img" />
  //         </Col>
  //         <Col lg="9" sm="11" className="auth-bg px-2 px-sm-3 px-lg-5">
  //           <Wizard
  //             ref={refLegal}
  //             steps={steps_regular}
  //             instance={el => {
  //               // stepper?.destroy()
  //               setStepper(el)
  //             }}
  //             headerClassName="d-flex justify-content-center"
  //             contentWrapperClassName="px-0 mt-2"
  //             className="register-multi-steps-wizard shadow-none h-100"
  //             activeIndex={currentIndex}
  //             setActiveIndex={setCurrentIndex}
  //           />
  //         </Col>
  //       </Row>
  //     </div>
  //   )
  // }

  return (
    <div className="auth-wrapper auth-cover  d-flex justify-content-center ">
      <div>
        <CardText className="text-end nav-buttons" style={{ minWidth: 100 }}>
          <ul className="nav navbar-nav align-items-center ms-auto">
            <IntlDropdown />
          </ul>
        </CardText>
      </div>
      <Row className="auth-inner m-0">
        <img
          style={{ maxWidth: '250px' }}
          src={logo}
          alt="logo"
          className="brand-logo"
        />

        <Col lg="3" className="d-none d-lg-flex align-items-center p-0">
          <div className="w-100 d-lg-flex align-items-center justify-content-center register-img" />
        </Col>
        <Col lg="9" sm="11" className="auth-bg px-2 px-sm-3 px-lg-5">
          <Wizard
            ref={refLegal}
            // steps={steps}
            steps={steps.slice(
              0,
              legalRepresentative ? steps.length : steps.length - 2
            )}
            instance={el => setStepper(el)}
            headerClassName="d-flex justify-content-center"
            contentWrapperClassName="px-0 mt-2"
            className="register-multi-steps-wizard shadow-none h-100"
            activeIndex={currentIndex}
            setActiveIndex={setCurrentIndex}
          />
        </Col>
      </Row>
    </div>
  )
}

export default RegisterAdmin
